import $ from 'jquery-custom';
import _ from 'underscore';
import Environment from 'application_environment';

function add_marker(gmap, geocoder, latlngbounds, marker) {
  geocoder.geocode({ 'address': marker[1] }, function geocode_callback(results, status) {
    let latlng;
    let gmarker;

    if (status === google.maps.GeocoderStatus.OK) {
      latlng = results[0].geometry.location;
      latlngbounds.extend(latlng);
      gmarker = new google.maps.Marker({
        position: latlng,
        map: gmap,
        title: marker[0]
      });
      gmap.fitBounds(latlngbounds);
      gmap.setCenter(latlngbounds.getCenter());
    } else {
      if (typeof Honeybadger === "object") {
        Honeybadger.notify("google.maps.GeocoderStatus." + status);
      }
    }
  });
}

function drawShow(addressInput) {
  // default lat/lng in center of US
  let lat = 40.4230;
  let lng = -98.7372;
  let zoom = 4;
  const geocoder = new google.maps.Geocoder();
  let addressPicker;

  // HACK: disable autofill/autocomplete on the address input in Chrome.
  // Otherwise the native address autofill is invoked and covers the Places
  // Autocomplete added below.
  addressInput.on("focus", function () {
    $(this).attr('autocomplete', 'new-password');
  });

  // addresspicker ignores inputs with values (only placing a marker after
  // the user chooses an address) so we need to geocode the address to
  // manually set the map center
  geocoder.geocode({ 'address': addressInput.val() }, function (results, status) {
    if (status === google.maps.GeocoderStatus.OK) {
      lat = results[0].geometry.location.lat();
      lng = results[0].geometry.location.lng();
      zoom = 10;
    }

    const input = addressInput[0];
    const map = new google.maps.Map(document.getElementById("map"), {
      center: { lat: lat, lng: lng },
      zoom: 13,
    });
    const options = {
      componentRestrictions: { country: "us" },
      fields: ["geometry"],
      origin: map.getCenter(),
      strictBounds: false,
      types: ["address"],
    };
    const autocomplete = new google.maps.places.Autocomplete(input, options);
    const marker = new google.maps.Marker({
      map,
      anchorPoint: new google.maps.Point(0, -29),
    });

    marker.setPosition({ lat: lat, lng: lng });
    marker.setVisible(true);

    autocomplete.addListener("place_changed", function () {
      marker.setVisible(false);
      const place = autocomplete.getPlace();

      if (!place.geometry || !place.geometry.location) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        // window.alert("No details available for input: '" + place.name + "'");
        return;
      }

      map.setCenter(place.geometry.location);
      marker.setPosition(place.geometry.location);
      marker.setVisible(true);
    });
  });
}

function drawIndex(map) {
  const data_markers = map.data('markers');
  const geocoder = new google.maps.Geocoder();
  const latlngbounds = new google.maps.LatLngBounds();
  const gmap = new google.maps.Map(map[0], {
    zoom: 4,
    center: latlngbounds.getCenter(),
    mapTypeId: google.maps.MapTypeId.ROADMAP
  });

  _.each(data_markers, function (data_marker, i) {
    add_marker(gmap, geocoder, latlngbounds, data_marker);
  });
}

window.initMap = function initMap() {
  const indexMap = $("#index_map");
  const addressInput = $("#location_full_address");

  if (addressInput.length > 0) {
    drawShow(addressInput);
  }

  if (indexMap.length > 0) {
    drawIndex(indexMap);
  }
};

(function ()  {
  const env = Environment();

  if (env.rails_env === "test") {
    return;
  }

  if ($("#index_map").length || $("#location_full_address").length) {
    const script = document.createElement('script');

    script.setAttribute("type", "text/javascript");
    document.body.appendChild(script);
    script.src = "https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyBS_w9kvz-LAMh8YTL9KnAtwA6LNR8cwis&callback=initMap";
  }
}());
