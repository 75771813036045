import $ from 'jquery-custom';

function redirect401(event, xhr) {
  if (xhr.status === 401) {  // Unauthorized, perhaps timed out.
    event.preventDefault();
    event.stopImmediatePropagation();
    window.top.location = "/";
  }
}

$(document).on('ajax:error', function (event) {
  const [data, status, xhr] = event.detail;

  redirect401(event, xhr);
});

/* eslint-disable no-jquery/no-event-shorthand */
$(document).ajaxError(function (event, xhr, settings, thrownError) {
  redirect401(event, xhr);
});

window.infoModal = function (message, okCallback) {
  $('#infoModalTitle').html(message);
  $('#infoModal').modal('show');
  $('#btnClose').off('click');
  $('#btnClose').on('click', function () {
    if(okCallback)
      okCallback();
  });
};

$(function () {
  $(document).on("click", '._pendo-resource-center-home-list', function () {
    const currentHost = window.location.host;
    if (currentHost != "control.continuity.net") {
      setTimeout(function () {
        $('._pendo-text-link').each(function (index, link) {
          const supportUrl = $(link).attr("href");        
          const endpoint = "https://" + currentHost + "/zendesk/sign_in?return_to=" + supportUrl;
          $(link).attr("href", endpoint);
        });

        $("._pendo-resource-center-sandbox-module-code-container a").each(function (index, link){
          const supportUrl = $(link).attr("href");
          if (supportUrl) {
            const endpoint = "https://" + currentHost + "/zendesk/sign_in?return_to=" + encodeURIComponent(supportUrl);
            const parsedLink = new URL(supportUrl);
            if (parsedLink.hostname === "support.continuity.net") {
              $(link).attr("href", endpoint);
            }
          }
        });
      }, 1500);
    }
  });
});