import consumer from "./consumer";

consumer.subscriptions.create("BackgroundJobChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("Connected");
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
     console.log("DisConnected");
  },

  received(data) {
    console.log (data);
    // Called when there's incoming data on the websocket for this channel
    if (data.url && window.sessionStorage.getItem(data.uuid) != null)
      {
        window.location.href = data.url;
      }
  }
});


