import $ from 'jquery-custom';

function enableTextInputIfValueIsOther($select) {
  const textInput = $select.parent().find('input.select_with_other_text.form-control');
  if ($select.val() === 'Other') {
    $(textInput).removeAttr('disabled');
  }
  else {
    $(textInput).val('').attr('disabled', 'disabled');
  }
}

$(document).on('change', 'select.select_with_other_text.form-control', function (e) {
  const $select = $(this);

  enableTextInputIfValueIsOther($select);
});


$(function () {
  $('select.select_with_other_text.form-control').each(function (i, select) {
    enableTextInputIfValueIsOther($(select));
  });
});
