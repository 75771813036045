import $ from 'jquery-custom';
import _ from 'underscore';
import Backbone from 'backbone';

Backbone.Model.prototype.toJSON = function () {
  let attributes;
  const csrf_param = $('meta[name="csrf-param"]').prop('content');
  const csrf_token = $('meta[name="csrf-token"]').prop('content');

  if (this.paramRoot) {
    attributes = {};
    attributes[this.paramRoot] = _.clone(this.attributes);
    if (attributes[this.paramRoot].utf8) {
      attributes.utf8 = attributes[this.paramRoot].utf8;
      delete attributes[this.paramRoot].utf8;
    }
  } else {
    attributes = _.clone(this.attributes);
  }

  if (csrf_param && csrf_token) {
    attributes[csrf_param] = csrf_token;
  }

  return attributes;
};

// This is pretty simple right now: it drops any field names.  But it's better
// than not showing an error at all (which was happening in the bug we fixed).
Backbone.Model.prototype.errorsToString = function () {
  const errorMessage = _.reduce(this.get('errors'), function (accumulator, element) {
    if (element && element.length) {
      accumulator.push(element);
    }

    return accumulator;
  }, []).join(', ');

  return errorMessage;
};

Backbone.View.prototype._clearErrors = function () {
  this.$('.invalid-feedback').remove();
  this.$('.is-invalid').removeClass('is-invalid');
};

Backbone.View.prototype._addErrors = function () {
  const _this = this;
  const root = this.model.paramRoot;
  const selector = _.template('#{{ root }}_{{ attr }}_input');

  _.each(this.model.get("errors"), function (msgs, attr) {
    _this.$(selector({root: root, attr: attr})).addClass("is-invalid").after('<div class="invalid-feedback">' + msgs.join(", ") + '</div>');
  });
};

Backbone.View.prototype.renderErrors = function () {
  this._clearErrors();
  this._addErrors();
};

Backbone.View.prototype.resetForm = function () {
  this.$('form').each(function () {
    this.reset();
  });
  this._clearErrors();
};

Backbone.View.prototype.setInputValues = function setInputValues() {
  const _this = this;
  const single_tmpl = _.template(':input[name="{{ paramRoot }}[{{ name }}]"]:not([type="hidden"][value])');
  const multi_tmpl = _.template(':input[name="{{ paramRoot }}[{{ name }}][]"]:not([type="hidden"][value])');

  this.$("li#custom_field_parent_input").remove();

  _.each(this.model.attributes, function (value, name) {
    const single_input = _this.$(single_tmpl({paramRoot: _this.model.paramRoot, name: name}));
    const multi_inputs = _this.$(multi_tmpl({paramRoot: _this.model.paramRoot, name: name}));

    multi_inputs.val(value); // multi-select select, radio, or checkbox; assume value is an array

    if (single_input.is(':checkbox')) {
      single_input.prop('checked', value);
    } else {
      single_input.val([value]);
    }
  });
};
