window._window = window; // useful for creating spys since you can't spy on window

import "core-js/stable";
import "regenerator-runtime/runtime";

import Rails from '@rails/ujs';
Rails.start();

import $ from 'jquery-custom';

import 'global_handlers';

import _ from 'underscore';
window._ = _;

// Support "erb" and "mustache" style templating in _ templates.  Primarily for legacy ToDoScript support.
_.templateSettings = {
  interpolate : /(?:\{\{|<%=)([\s\S]+?)(?:\}\}|%>)/g,
  escape      : /(?:\{\{\{|<%-)([\s\S]+?)(?:\}\}\}|%>)/g
};

import Backbone from 'backbone';
import "shared/lib/backbone_modules";

require('chosen-js/chosen.jquery');
//import "chosen-js/chosen.css"
import "chosen-js/stylesheets/custom";

import plupload from 'plupload/js/plupload.full.min';
window.plupload = plupload;
require('plupload/js/jquery.plupload.queue/jquery.plupload.queue');

require('pwstrength-bootstrap/dist/pwstrength-bootstrap');

import "bootstrap-daterangepicker/global-init-daterangepicker";

import 'bootstrap';

import 'location-services';

import 'charts';
import 'checkboxes';
import 'sandbox';
import 'target_blank';
import 'select_with_other_text_input';
import 'chat';
import '_application';
import 'channels';

$(document).on('ajax:success', '.dialog', function (event) {
  const [data, status, xhr] = event.detail;

  $($.parseHTML(xhr.responseText)).filter("*").modal();
});

$(function () {
  $('*[data-toggle-text]').on("click", function () {
    const $this = $(this);
    const newText = $this.data('toggle-text');
    $this.data('toggle-text', $this.text());
    $this.text(newText);
  });
});

$(function () {
  $('.disabled-form :input').attr('disabled', true);
});

window.activeAjaxRequests = 0;

(function (open) {
  return XMLHttpRequest.prototype.open = function (method, url, async, user, pass) {
    this.addEventListener('readystatechange', (function () {
      if (this.readyState == 1) {
        window.activeAjaxRequests += 1;
      } else if (this.readyState == 4){
        window.activeAjaxRequests -= 1;
      }
    }), false);
    return open.call(this, method, url, async, user, pass);
  };
}(XMLHttpRequest.prototype.open));
